<template>
  <div>
    <b-modal
      :title="creationMode ? 'Ajout de compétiteur' : 'Modification de compétiteur'"
      centered
      :static="true"
      :lazy="true"
      v-model="modalShown"
      button-size="lg"
      @ok="handleOk"
      @close="close"
      @hidden="hide"
      @show="show"
      ref="dialog"
    >
        <vue-bootstrap-typeahead
            v-show = 'creationMode && !newCompetitor'
            v-model="editSearch"
            :data="searchSuggestions"
            placeholder="Entrez le nom de la paire"
            :minMatchingChars="1"
            prepend="Rechercher"
            @hit="editSearch_Hit"
            :serializer="s => s.name"
        />
        <helper-note v-show="creationMode" :variant="creationHelperNoteVariant">
            <div>Privilégiez toujours la recherche.<br/>
            Si aucun résultat ne correspond, essayez de modifier l'orthographe.<br/>
            Si, finalement, vous ne trouvez vraiment pas le compétiteur, ou s'il s'agit d'un nouveau compétiteur inconnu dans la base, saisissez les informations ci-dessous
            </div>
            <b-button variant="secondary" size="sm" @click="newCompetitor_click" :disabled="newCompetitor">Nouveau</b-button>
            <b-button variant="secondary" size="sm" @click="cancelNewCompetitor_click" :disabled="!newCompetitor">Annuler Nouveau compétiteur</b-button>
        </helper-note>

        <checkbox-form-field label='Concours en "open"' v-if="competitionIsOpen && !creationMode" caption='Concours en mode "open"' v-model="editingCompetitorIsOpen" switch>
        <br/>
        </checkbox-form-field>

        <b-form ref="form" @submit.stop.prevent="editNonIndividualCompetitor_Ok">
          <form-field caption="Nom" required >
            <b-form-input type="text"
              v-model="editingCompetitorName"
              required
              :disabled="creationMode && !newCompetitor"
              :state="editingCompetitorNameIsValid"
              @blur="checkEditingCompetitorNameValidity"
              @input="editingCompetitorNameIsValid = null"
              invalidFeedBack="Le nom est obligatoire."
            />
          </form-field>

          <form-field caption="Nom abbrégé (court)" >
            <b-form-input type="text"
              v-model="editingCompetitorShortname"
              :disabled="creationMode && !newCompetitor"
              :state="editingCompetitorShortnameIsValid"
              @blur="checkEditingCompetitorShortnameValidity"
              @input="editingCompetitorShortnameIsValid = null"
            />
          </form-field>

          <checkbox-form-field label='Concours en "open"' v-if="competitionIsOpen && creationMode" caption='Concours en mode "open"' v-model="editingCompetitorIsOpen" switch>
          </checkbox-form-field>

          <form-field caption="Co-équipiers" v-if="d_editingCompetitorId && d_editingCompetitorId.members">
            <b-container fluid>
              <team-members-list
                :minMemberCount="d_minMemberCount"
                :maxMemberCount="d_maxMemberCount"
                :showName="true"
                :showRollnumber="true"
                :canAdd="true"
                :canEdit="true"
                :canRemove="true"
                :items="d_editingTeamCompetitors"
                :competitor="d_editingCompetitorId"
                @add="addTeamMember"
                @search="addTeamMember"
                @edit="editTeamMember"
                @remove="removeTeamMember"
                @editLicensee = 'editCompetitorLicensee'
                ref="teamMembers"
              >
              </team-members-list>
            </b-container>
         </form-field>
        </b-form>
    </b-modal>

    <!-- Dialogue d'ajout/édition d'un équipier -->
    <people-edit-dialog
      v-model="d_showPeopleEditDialog"
      :editCallContext="d_peopleEditDialogContext"
      :creationMode="d_peopleEditDialogCreationMode"
      creationModeTitle="Recherche de pilote"
      editionModeTitle="Modification des données du pilote"
      :search_suggestions="d_peopleEditDialogSearchSuggestions"
      @select="member_select"
      @create="member_create"
      @update="member_update"
    >
    </people-edit-dialog>

    <!-- Dialogue de traitement de l'état de validité de la license d'un pilote -->
    <licensee-edit-dialog
      ref="licenceDialog"
      v-model="showEditLicenseeDialog"
      @hide="showEditLicenseeDialog = false"
      @ok="editLicenseeDialog_ok"
      :callContext="d_peopleEditDialogContext"
      :federalNumber="selectedLicensee"
      :year="selectedLicenceYear"
      :peopleName="selectedLicencePeopleName"
    >
    </licensee-edit-dialog>
  </div>
</template>

<script>
import { RST_COMPETITOR, /*RST_COMPETITOR_COMPOSITION,*/ RST_COMPETITION, REF_CATEGORY, COMPETITOR, PEOPLE } from "@/LocalDB";
import FormField from '@/components/Panels/FormField.vue';
import TeamMembersList from '@/components/TeamMembersList.vue';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import HelperNote from "@/components/HelperNote";
import CompetitionsConfigurationHelper from "@/services/helpers/competitionsConfigurationHelper";
import PeopleEditDialog from "./PeopleEditDialog.vue"
import LicenseeEditDialog from './LicenseeEditDialog.vue';
import CheckboxFormField from "@/components/Panels/CheckboxFormField.vue";

export default {
  components: { FormField, TeamMembersList, VueBootstrapTypeahead, HelperNote, PeopleEditDialog, LicenseeEditDialog, CheckboxFormField },
  props: {
    value: { type:Boolean, default: false},
    competition_id : { type:String, required: true},
    level: { type:String, required: true},
    category: { type: String, required: true},
    competitor_id: { type: Object, default: null},
    creationMode: { type:Boolean, default: false},
    search_suggestions: { type:Array, default: null},
    members: { type:Array, required: true },
    minMemberCount: { type:Number, required: true},
    maxMemberCount: { type:Number, required: true},
  },
  data(){
    return {
      modalShown: this.value,
      editSearch: '',

      d_editingCompetitorId: this.competitorId,
      editingCompetitorName : (this.d_editingCompetitorId != null) ? this.d_editingCompetitorId.name : null, editingCompetitorNameIsValid: null,
      editingCompetitorShortname : (this.d_editingCompetitorId != null) ? this.d_editingCompetitorId.shortname : null, editingCompetitorShortnameIsValid: null,
      editingCompetitorIsOpen: false,
      d_editingTeamCompetitors: this.members || [], //(this.d_editingCompetitorId != null && this.d_editingCompetitorId.members != null) ? this.d_editingCompetitorId.members.map(a => a) : [],

      searchSuggestions: [],
      newCompetitor: false,
      creationHelperNoteVariant: 'info',

      d_showPeopleEditDialog: false,
      d_peopleEditDialogContext: {},
      d_peopleEditDialogCreationMode: false,
      d_peopleEditDialogSearchSuggestions: [],
      
      showEditLicenseeDialog: false,
      selectedLicensee: '',
      selectedLicencePeopleName: '',
      selectedLicenceYear: null,

      d_minMemberCount : this.minMemberCount,
      d_maxMemberCount: this.maxMemberCount,
    };
  },
  computed:{
    COMPETITION(){
      var comp = RST_COMPETITION.query().where("competition_id", this.competition_id).first();
      return comp;
    },
    CATEGORY(){
      var cat = REF_CATEGORY.query().where("code", this.category).first();
      return cat;
    },
    competitionIsOpen(){
      return this.COMPETITION.isOpen;
    },
  },
  methods:{
    checkFormValidity(){
      this.checkEditingCompetitorNameValidity();
      this.checkEditingCompetitorShortnameValidity();

      const valid = this.$refs.form.checkValidity();
      return valid;
    },
    handleOk(bvModalEvent){
      bvModalEvent.preventDefault();
      this.editNonIndividualCompetitor_Ok();
    },
    close(){
      this.$emit("close");
      this.$emit("input", false);
    },
    hide(){
      this.$emit("hide");
      this.$emit("input", false);
    },
    hideDialog(){
      this.$refs['dialog'].hide();
      this.$emit("input", false);
    },
    show(){
      this.reset(!this.creationMode && this.d_editingCompetitorId != null);
      this.$emit("input", true);
    },
    editSearch_Hit(selected){
        this.d_editingCompetitorId = selected;
        this.d_editingCompetitorId.members = [];
        this.editingCompetitorName = selected.name;
        this.editingCompetitorShortname = (selected.shortname == null || selected.shortname == selected.name) ? '' : selected.shortname;
    },
    async editNonIndividualCompetitor_Ok(){
      if(!this.checkFormValidity()) {
        return;
      }
      await this.$showSaver();
      this.hideDialog();
      //this.saving=true;
      setTimeout(this.completeSaving, 100);
    },
    async completeSaving(){

      var comp = this.d_editingCompetitorId;
      var members = this.d_editingCompetitorId.members.map((m, index) => {
        if(m.people_id)
          return { people_id: m.people_id, memberOrder: index};
        else
          return { people_id: m.id, memberOrder: index};
      });

      if(this.creationMode){
        if(this.newCompetitor || comp == null){
          comp = CompetitionsConfigurationHelper.create_new_non_individual_competitor(this.COMPETITION.competition_type, this.category, this.editingCompetitorName);
        }
        console.log('catCompetitors', this.competition_id, this.level, this.category);
        var catCompetitors = RST_COMPETITOR.query()
                .where("competition_id", this.competition_id)
                .where("level", this.level)
                .where("category", this.category)
                .orderBy("subscription_order")
                .get();
        var lastCatCompetitor = (catCompetitors != null && catCompetitors.length > 0)
                              ? catCompetitors[catCompetitors.length - 1]
                              : null;
        var subscriptionOrder = (lastCatCompetitor != null)
                              ? lastCatCompetitor.subscription_order
                              : 0;

        if(members.length > 0)
        {
          await CompetitionsConfigurationHelper.replace_membersAsync(this.competition_id, comp.id, this.round_number, members);
        }

        if(comp != null)
        {
            CompetitionsConfigurationHelper.attach_competitor(this.competition_id, comp.id, this.level, this.category, subscriptionOrder + 1, this.editingCompetitorIsOpen);
            this.$emit('competitorCreated');
        } else {
            throw 'should not come here !';
//                  var competitor = CompetitionsConfigurationHelper.create_new_non_individual_competitor(this.COMPETITION.competition_type, this.category, this.editingCompetitorName);
//                  CompetitionsConfigurationHelper.attach_competitor(this.currentCompetition_id, competitor.id, this.editingCompetitorsLevel.code, this.editingCompetitorsCategory.code, subscriptionOrder + 1);
        }
        this.$hideSaver();
        this.toastSaved("Compétiteur ajouté");
        this.reset();
        this.$emit("ok");
      } else {
        var success = true;
        var shortname = this.editingCompetitorShortname;
        if(shortname.trim() == '')
          shortname = this.editingCompetitorName;
        if(this.editingCompetitorName != this.d_editingCompetitorId.name
          || shortname != this.d_editingCompetitorId.shortname)
        {
          success = await CompetitionsConfigurationHelper.updateNonIndividualCompetitorDataAsync(this.d_editingCompetitorId.id, this.editingCompetitorName, shortname);
        }
        if(success && (this.editingCompetitorIsOpen != this.competitor_id.isOpen))
          success = await CompetitionsConfigurationHelper.changeCompetitorIsOpenStatusAsync(this.competition_id, this.editingCompetitorId.id, this.editingCompetitorIsOpen);

        if(success && members.length > 0){
          success = await CompetitionsConfigurationHelper.replace_membersAsync(this.competition_id, comp.id, this.round_number, members);
        }
        this.reset();
        this.$hideSaver();
        this.toastAsyncSaved(success, "Compétiteur modifié");
        this.$emit("ok");
        this.saving = false;
      }
    },
    newCompetitor_click(){
      this.newCompetitor = true;
      this.creationHelperNoteVariant = 'danger';
      this.d_editingCompetitorId = { members: [] };
    },
    cancelNewCompetitor_click(){
      this.newCompetitor = false;
      this.d_editingCompetitorId = this.competitor_id
      this.editingCompetitorNameIsValid = null;
      this.editingCompetitorShortnameIsValid = null;
      this.creationHelperNoteVariant = 'info';
    },
    reset(exceptEditionFields = false){
      this.editSearch = '';

      if(!exceptEditionFields)
      {
        this.d_editingCompetitorId = null;
        this.editingCompetitorName = '';
        this.editingCompetitorShortname = '';
      }

      this.editingCompetitorNameIsValid = null;
      this.editingCompetitorShortnameIsValid = null;
      this.newCompetitor = false;
      this.d_editingTeamCompetitors = [];
      this.creationHelperNoteVariant = 'info'
    },
    checkEditingCompetitorNameValidity(){
      this.editingCompetitorNameIsValid = null;
      if((!this.creationMode || this.newCompetitor) && (this.editingCompetitorName == null || this.editingCompetitorName.trim() == ''))
        this.editingCompetitorNameIsValid = false;
      return this.editingCompetitorNameIsValid == null ? true : false;
    },
    checkEditingCompetitorShortnameValidity(){
      this.editingCompetitorShortnameIsValid = null;
      return this.editingCompetitorShortnameIsValid == null ? true : false;
    },
    addTeamMember(currentMember){
      this.d_editingTeamCompetitors = this.d_editingCompetitorId.members;
      this.d_peopleEditDialogContext = currentMember;
      this.d_peopleEditDialogCreationMode = true; 
      var competition_type = this.COMPETITION.competition_type;
      var categ = this.CATEGORY;
      if(this.d_editingTeamCompetitors.length == 0) this.d_editingTeamCompetitors = this.d_editingCompetitorId.members.map(a => a);
      // il faut maintenant préparer la liste des personnes possibles en commençant par les compétiteurs correspondant en terme de 2L/4L
      this.d_peopleEditDialogSearchSuggestions = COMPETITOR.query()
                    .where('competition_type', competition_type)
                    .where(item => item.CATEGORY.isIndividual
                                && item.CATEGORY.isCva == categ.isCva
                                && item.CATEGORY.isIndoor == categ.isIndoor
                                && item.CATEGORY.isFreestyle == categ.isFreestyle
                                && item.CATEGORY.isDualLines == categ.isDualLines
                                && item.CATEGORY.isMultiLines == categ.isMultiLines)
                    .where('isIndividual', true)
                    .get()
                    .map(item => {if(item.POPLE == null) console.log ('NO PEOPLE', item); return item.PEOPLE})
                    .filter(item => {
                      return this.d_editingTeamCompetitors.findIndex(tm => tm.people_id == item.id) < 0

                    }).map(item => {
                        var mapped = item;
                        console.log(item.name, item);
                        mapped.forSuggestion = '** ' + item.name + ((item.isFederal == true) ?  ' - ' + item.federal_number : '');
                        mapped.matches = '**'
                        return mapped;
                    });
      // On ajoute à ces suggestion la liste des pilotes 4L si la catégorie est 2L est vice-versa.
      COMPETITOR.query()
          .where('competition_type', competition_type)
                    .where(item => item.CATEGORY.isIndividual
                                && item.CATEGORY.isCva == categ.isCva
                                && item.CATEGORY.isIndoor == categ.isIndoor
                                && item.CATEGORY.isFreestyle == categ.isFreestyle
                                && !(item.CATEGORY.isDualLines == categ.isDualLines
                                    && item.CATEGORY.isMultiLines == categ.isMultiLines))
          .where('isIndividual', true)
          .get()
          .map(item => item.PEOPLE)
          .forEach(comp => {
              if(this.d_peopleEditDialogSearchSuggestions.findIndex(item => item.id == comp.id) < 0 )
              {
                if(this.d_editingTeamCompetitors.findIndex(tm => tm.people_id == comp.id) < 0){

                    var mapped = comp;
                    //mapped.id = null;
                    mapped.forSuggestion = '* ' + mapped.name + ((mapped.isFederal == true) ? ' - ' + mapped.federal_number : '');
                    mapped.matches = '*';
                    this.d_peopleEditDialogSearchSuggestions.push(mapped);
                }
              }
          });
          PEOPLE.query()
              .get()
              .forEach(people => {
                  if(this.d_peopleEditDialogSearchSuggestions.findIndex(item => item.id == people.id) < 0)
                  {
                      if(this.d_editingTeamCompetitors.findIndex(tm => tm.people_id == people.id) < 0) {
                        var mapped = people;
                        mapped.forSuggestion = people.name + ((people.isFederal == true) ? ' - ' + people.federalNumber : '');
                        mapped.matches = '';
                        this.d_peopleEditDialogSearchSuggestions.push(mapped);
                    }
                  }
              });        
      this.d_showPeopleEditDialog = true;
    },
    editTeamMember(currentMember){
      this.d_peopleEditDialogContext = currentMember;
      this.d_peopleEditDialogCreationMode = false; 
      this.d_showPeopleEditDialog = true;
    },
    removeTeamMember(member){
      var index = this.d_editingCompetitorId.members.findIndex(m => m.people_id == (member.people_id || member.id));
      if(index >= 0){
        this.d_editingCompetitorId.members.splice(index,1);
        this.$refs.teamMembers.refresh();
      }
    },
    async member_select(data){
      data.people.people_id = data.people.id;
      this.setMember(data);
    },
    editCompetitorLicensee(competitor){
      this.selected_d_editingCompetitorId = competitor;
      this.d_peopleEditDialogContext = competitor;
      var people = PEOPLE.query().where("id", competitor.people_id).first();
      this.selectedLicensee = people.federalNumber;
      this.selectedLicencePeopleName= people.name;
      this.selectedLicenceYear = this.COMPETITION.year;
      this.showEditLicenseeDialog = true;
    },
    async member_create(data){
      await this.showSaver();
      try{
        var comp = this.COMPETITION;
        var compType = comp.competition_type
        var regulation = comp.REGULATION;
        var compCateg = this.CATEGORY;
        var categ = REF_CATEGORY.query()
                      .where("isDualLines", compCateg.isDualLines)
                      .where("isMultiLines", compCateg.isMultiLines)
                      .where("isCva", regulation.isCva)
                      .where("isIndoor", regulation.isIndoor)
                      .where("isFreestyle", regulation.isFreestyle)
                      .first();
        var result = await CompetitionsConfigurationHelper.create_new_individual_competitor_from_scratchAsync(compType, categ.code, data.firstname, data.lastname, data.federal_number, data.sexe, data.birthdate, data.email);
        if(result.success && (data.licenseeState.isValid != data.context.federalNumber.isValid 
          || data.licenseeState.isCompetitor != data.context.federalNumber.isCompetitor
          || data.licenseeState.isMedicalCertificateValid != data.context.isMedicalCertificateValid))
          result.success = await CompetitionsConfigurationHelper.changeLicenceInfoAsync(data.federal_number, data.licenseeState.year, data.licenseeState.isValid, data.licenseeState.isCompetitor, data.licenseeState.isMedicalCertificateValid);
        data.people = result.people;
        this.setMember(data);
        this.toastAsyncSaved(result.succes, 'Compétiteur créé');
      }catch(error){
        this.toastSaveFailure(error);
      }
      this.$hideSaver();
    },    
    async member_update(data){
      await this.showSaver();
      try{
        var compCateg = this.CATEGORY;
        var competitor = COMPETITOR.query().where("people_id", data.context.people_id)
                          .where("competition_type", this.COMPETITION.competition_type)
                          .where(c => c.CATEGORY.isIndividual && c.CATEGORY.isDualLines == compCateg.isDualLines && c.category.isMultiLines == compCateg.isMultiLines)
                          .first();
        var result = await CompetitionsConfigurationHelper.updateIndividualCompetitorDataAsync(competitor.id, data.firstname, data.lastname, data.federal_number);
        if(result && (data.licenseeState.isValid != data.context.federalNumber.isValid 
          || data.licenseeState.isCompetitor != data.context.federalNumber.isCompetitor
          || data.licenseeState.isMedicalCertificateValid != data.context.isMedicalCertificateValid))
          result = await CompetitionsConfigurationHelper.changeLicenceInfoAsync(data.federal_number, data.licenseeState.year, data.licenseeState.isValid, data.licenseeState.isCompetitor, data.licenseeState.isMedicalCertificateValid);
        competitor = COMPETITOR.query().where("id", competitor.id).first();
        data.people = {...competitor.PEOPLE, ...data.licenseeState};
        this.toastAsyncSaved(result, "Compétiteur modifié");
        this.setMember(data);
      }catch(error){
        this.toastSaveFailure(error);
      }
      this.$hideSaver();
    },
    async editLicenseeDialog_ok(newState){
      var data = newState.context;
      data.federalNumber.isValid = newState.isValid;
      data.federalNumber.isCompetitor = newState.isCompetitor;
      data.federalNumber.isMedicalCertificateValid = newState.isMedicalCertificateValid;
      this.d_editingCompetitorId.members.splice(newState.context.index, 1, data);
      this.$refs.teamMembers.refresh();
    },
    setMember(data){
      if(!data.context || this.d_editingCompetitorId.members.length <= data.context.index){
        this.d_editingCompetitorId.members.push(data.people);
      }
      else{
         this.d_editingCompetitorId.members.splice(data.context.index, 1, data.people);
              }
      this.d_editingTeamCompetitors = this.competitor_id.members;
      this.$refs.teamMembers.refresh();
    }
  },
  watch:{
    search_suggestions: function (newVal, oldVal){
      if(newVal == null)
        newVal = [];
      if(!this.arraysEquals(newVal, oldVal))
        this.searchSuggestions = newVal;
    },
    value: function(newVal)
    {
      this.modalShown = newVal;
    },
    competitor_id(newVal)
    {
        this.d_editingCompetitorId = newVal;
        this.editingCompetitorName = newVal.name;
        this.editingCompetitorShortname = (newVal.shortname == null || newVal.shortname.trim() == newVal.name.trim()) ? '' : newVal.shortname;
        this.editingCompetitorIsOpen = newVal.isOpen;
    },
    members(newVal)
    {
      this.d_editingTeamCompetitors = newVal;
    },
    minMemberCount(newVal)
    {
      this.d_minMemberCount = newVal;
    },
    maxMemberCount(newVal){
      this.d_maxMemberCount = newVal;
    },
  }
}
</script>
